




























































































































































































































































































































































































































import {
  Component,
  Vue, Watch,
} from 'vue-property-decorator';
import {
  getComponent,
  getConfigEnv, jsonParse,
} from '@/utils/helpers';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import mapLayersModule from '@/store/modules/mapLayersModule';
import assetsModule from '@/store/modules/assetsModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import accountModule from '@/store/modules/accountModule';
import { getNameByEmail } from '@/utils/users';
import {
  ALL_TASQS_LIST_ITEM,
  POST_SHAPEFILE_LAMBDA_NAME,
  POST_SHAPEFILE_LAMBDA_REGION,
  IDENTITY_POOL_REGION,
  IDENTITY_POOL_ID,
  SHAPEFILE_BUCKET_NAME,
  SHAPEFILE_BUCKET_REGION,
  MAPBOX_ACCESS_TOKEN,
} from '@/lib/constants';
import MapLayer from '@/interfaces/mapLayers/MapLayer';
import AWS from 'aws-sdk';
@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    MapLayoutManager: () => getComponent('map/MapLayoutManager'),
     TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
     TasqsMobile: () =>  getComponent('tasqs/TasqDetailMobile'),
  },
})
export default class Map extends Vue {
    uploadShapefilePopupOpen = false;
    friendlyLayerName = '';
    errorMessage = '';
    windowWidth = window.innerWidth;
    dataLoading = false;
    showModal = false;
    assetTypeOptions = ['Wells', 'Facilites', 'Pipelines', 'Valves', 'Lease Roads', 'Lease Boundaries', 'Other']
    isLayoutManagerOpen = false;
    isAssetManagerOpen = false;
    isLoadingMapview = false
	showLayerMenuEdit = ''
	assetTypeOptionSelected = ''
    selectedAssetDetails = 'Wells';
	isRemovingMapLayer = false
    map_view_types = [{
      name: 'Default',
      icon: 'map',
    },
    {
      name: 'Satellite',
      icon: 'satellite',
    },
    {
      name: 'Terrain',
      icon: 'terrain',
    },
    ];
    mounted() {
     this.$nextTick(() => {
       window.addEventListener('resize', this.onResize);
     });
   }
   beforeDestroy() {
     tasqsListModule.setActiveTasq('')
     tasqsListModule.setKanbanSelectedTasq('')
     assetsModule.setActiveProducingTasq('')
     window.removeEventListener('resize', this.onResize);
   }
   
   @Watch('isMobile')
    onChangeIsMobile(value){
      let el = document.getElementById('top-vertical-setting')!
      if(value){
       el.style.display = 'none';
      }else {
        el.style.display = 'block';
      }
    }
    async created() {
      setCurrentName(this.getCurrentName);
      this.isLoadingMapview = true;
      tasqsListModule.setActivePage('Map')
      const promises: any[] = [];
      promises.push(mapLayersModule.getMapLayers());
      promises.push(tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM));
      promises.push(mapLayersModule.getMetadataLatLong());
      Promise.all(promises).then(() => {
        mapLayersModule.addMapLayer(this.metadataTableWellsRecord);
        fetch_geo_json(mapLayersModule.mapLayerList, this.metadataTableWellsRecord, this.tasqs);
        this.isLoadingMapview = false;
      }, (err) => {
        // error occurred
      });
      // await mapLayersModule.getMapLayers();
      // await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
      // await mapLayersModule.getMetadataLatLong()
    }
      get kanbanTasq(){
    return tasqsListModule.kanbanSelectedTasq
  }
  get isMobile() {
     return this.windowWidth < 769;
   }
   
  onResize() {
     this.windowWidth = window.innerWidth;
   }
     @Watch('kanbanTasq')
  async onkanbanTasqChange(id: string) {
    if(id){
      console.log(id)
      this.showModal = true;
    }else {
      this.showModal = false;
    }
   
  }
    get getCurrentName(): string {
      return getNameByEmail(accountModule.user.email);
    }
    get metadataTableWellsRecord() {
      return mapLayersModule.metadataTableWellsRecord;
    }
    get tasqs(): TasqJob[] {
      return tasqsListModule.tasqList;
    }
    get mapLayers(): MapLayer[] {
      return mapLayersModule.mapLayerList;
    }
    async deleteLayerMenu(mapLayer, layout_id) {
      if (mapLayer.assetType == 'METADATA_TABLE_WELLS') {
        return;
      }
      this.layerSelected(mapLayer, layout_id, true);
      this.isRemovingMapLayer = true;
      await mapLayersModule.deleteMapLayer({
        maplayer_id: mapLayer.id,
      });
      await mapLayersModule.getMapLayers();
      // this.isRemovingMapLayer = false
      location.reload();
    }
	hovering_over_element = ''
	showLayerMenuIcon(mapLayerMenuIconID) {
	  this.hovering_over_element = mapLayerMenuIconID;
	  // @ts-ignore
	  // showLayerMenuIconByID(mapLayerMenuIconID)
	}
	hideLayerMenuIcon(mapLayerMenuIconID) {
	  this.hovering_over_element = '';
	  // @ts-ignore
	  // hideLayerMenuIconByID(mapLayerMenuIconID)
	}
	toggleLayoutManagerOpen() {
	  this.isLayoutManagerOpen = !this.isLayoutManagerOpen;
	}
	toggleAssetManagerOpen() {
	  this.isAssetManagerOpen = !this.isAssetManagerOpen;
	}
	submitNewLayer() {
	  this.dataLoading = true;
	  if (stored_zip_file != null) {
	    readfiles(stored_zip_file);
	  }
	}
	layerSelected(mapLayer, layout_id, remove_markers) {
	  if (mapLayer.assetType == 'METADATA_TABLE_WELLS' || mapLayer.assetType == 'WELLS' || mapLayer.assetType == 'VALVES') {
	    updatePointMapMarkers(mapLayer, layout_id, remove_markers);
	  } else {
	    updateMapviewLayout(layout_id, remove_markers);
	  }
	}
   closeDialog(){
    this.showModal = false;
  }
	addNewLayer() {
	  this.uploadShapefilePopupOpen = true;
	  setupDragAndDropSection();
	}
}
const operator = getConfigEnv('OPERATOR_LOWERCASED');
let currentName = '';
let stored_zip_file;
let map;
const wellMarkers = {};
function setCurrentName(name) {
  currentName = name;
}
function get_lambda_shapefile_names(bucketName, operator, s3_url, key, file_name, extension) {
    document.getElementById('shapefileUploadStatusID') !.innerHTML = 'Preparing new layer...';
    AWS.config.update({
      region: IDENTITY_POOL_REGION,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IDENTITY_POOL_ID,
      }),
    });
    const lambda = new AWS.Lambda({
      region: POST_SHAPEFILE_LAMBDA_REGION,
      apiVersion: '2015-03-31',
    });
    // create JSON object for service call parameters
    // @ts-ignore
    const friendlyLayerName = document.getElementById('friendlyLayerNameID') !.value;
    // @ts-ignore
    const assetTypeSelection = document.getElementById('assetTypeSelectionID') !.value;
    const pullParams = {
      FunctionName: POST_SHAPEFILE_LAMBDA_NAME,
      InvocationType: 'RequestResponse', // Event | RequestResponse | DryRun
      LogType: 'None',
      Payload: JSON.stringify({
        Key: key,
        Bucket: bucketName,
        LayerName: friendlyLayerName,
        Username: currentName.toLowerCase(),
        AssetType: assetTypeSelection,
        ShapefileZipURL: s3_url,
        ShapefileName: file_name,
        Operator: operator,
        Extension: extension,
      }),
    };
    lambda.invoke(pullParams, (err, data) => {
      if (err) {
        console.log(err);
      } else {
        location.reload();
      }
    });
}
function showLayerMenuIconByID(id) {
	document.getElementById(id)!.style.display = 'block';
}
function hideLayerMenuIconByID(id) {
	document.getElementById(id)!.style.display = 'none';
}
function clearNewLayerDropArea() {
  stored_zip_file = null;
    // @ts-ignore
    document.getElementById('add_shapefile_input') !.value = '';
    setupNewLayerDropArea();
}
function setupNewLayerDropArea() {
  if (stored_zip_file != null) {
        document.getElementById('afterShapefileUploadID') !.style.display = 'grid';
        document.getElementById('defaultShapefileUploadAreaID') !.style.display = 'none';
        // @ts-ignore
        document.getElementById('fileDragName') !.value = stored_zip_file.name;
        // @ts-ignore
        document.getElementById('fileDragSize') !.value = stored_zip_file.size;
        // @ts-ignore
        document.getElementById('fileDragType') !.value = stored_zip_file.type;
  } else {
        document.getElementById('afterShapefileUploadID') !.style.display = 'none';
        document.getElementById('defaultShapefileUploadAreaID') !.style.display = 'block';
        // @ts-ignore
        document.getElementById('fileDragName') !.value = '';
        // @ts-ignore
        document.getElementById('fileDragSize') !.value = '';
        // @ts-ignore
        document.getElementById('fileDragType') !.value = '';
  }
}
function setupDragAndDropSection() {
  sleep(500).then(() => {
    const inputElement = document.getElementById('add_shapefile_input') !;
    inputElement.onchange = function (event) {
      // @ts-ignore
      const fileList = inputElement.files;
      stored_zip_file = fileList[0];
      setupNewLayerDropArea();
      // TODO do something with fileList.
    };
    const holder = document.getElementById('holder');
    // @ts-ignore
    holder.ondragover = function () {
      // @ts-ignore
      this.className = 'hover';
      return false;
    };
    // @ts-ignore
    holder.ondragexit = function () {
      // @ts-ignore
      this.className = '';
      return false;
    };
    // @ts-ignore
    holder.ondragend = function () {
      // @ts-ignore
      this.className = '';
      return false;
    };
    // @ts-ignore
    holder.ondragleave = function () {
      // @ts-ignore
      this.className = '';
      return false;
    };
    // @ts-ignore
    holder.ondrop = function (e) {
      // @ts-ignore
      this.className = '';
      e.preventDefault();
      // @ts-ignore
      stored_zip_file = e.dataTransfer.files[0];
      setupNewLayerDropArea();
    };
  });
}
function processShapefile(file) {
  const extension = file.name.split('.').pop();
	document.getElementById('shapefileUploadLoadingViewID') !.style.display = 'block';
    document.getElementById('shapefileUploadStatusID') !.innerHTML = 'Uploading shapefile...';
    AWS.config.update({
      region: SHAPEFILE_BUCKET_REGION,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IDENTITY_POOL_ID,
      }),
    });
    const s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      params: {
        Bucket: SHAPEFILE_BUCKET_NAME,
      },
    });
    const key = `${operator}/${file.name}`;
    s3.upload({
      Key: key,
      Bucket: SHAPEFILE_BUCKET_NAME,
      Body: file,
      ACL: 'private',
    },
    (err, data) => {
      if (err) {
        console.log(err, 'there was an error uploading your file');
      } else {
        const s3_url = `https://${SHAPEFILE_BUCKET_NAME}.s3.amazonaws.com/${key}`;
        console.log(`URL Generated: ${s3_url}`);
                document.getElementById('shapefileUploadStatusID') !.innerHTML = 'Uploading shapefile...';
                get_lambda_shapefile_names(SHAPEFILE_BUCKET_NAME, operator, s3_url, key, file.name, extension);
      }
    });
}
function readfiles(file) {
  if (file) {
    processShapefile(file);
  }
}
function updateMapviewLayout(layout_id, remove_markers) {
  const possible_layer_ids = [layout_id, `polygon-${layout_id}`, `multipolygon-${layout_id}`, `point-${layout_id}`, `linestring-${layout_id}`];
  for (let x = 0; x < possible_layer_ids.length; x++) {
    try {
      if (map.getLayer(possible_layer_ids[x])) {
        const visibility = map.getLayoutProperty(
          possible_layer_ids[x],
          'visibility',
        );
        if (visibility === 'visible' || remove_markers) {
          map.setLayoutProperty(
            possible_layer_ids[x],
            'visibility',
            'none',
          );
          if (map.getLayer(`${possible_layer_ids[x]}_outline`) != null) {
            map.setLayoutProperty(
              `${possible_layer_ids[x]}_outline`,
              'visibility',
              'none',
            );
          }
        } else {
          map.setLayoutProperty(
            possible_layer_ids[x],
            'visibility',
            'visible',
          );
          if (map.getLayer(`${possible_layer_ids[x]}_outline`) != null) {
            map.setLayoutProperty(
              `${possible_layer_ids[x]}_outline`,
              'visibility',
              'visible',
            );
          }
        }
      }
    } catch (error) {
      console.log("Didn't find layer with ID, skipping");
    }
  }
}
function get_type(type_string) {
  if (type_string == 'PIPELINES') {
    return 'line';
  }
  if (type_string == 'BATTERIES') {
    return 'circle';
  }
  if (type_string == 'WELLS') {
    return 'circle';
  }
  if (type_string == 'METADATA_TABLE_WELLS') {
    return 'circle';
  }
  if (type_string == 'VALVES') {
    return 'circle';
  }
  if (type_string == 'LEASE_ROADS') {
    return 'line';
  }
  if (type_string == 'LEASE_BOUNDARIES') {
    return 'fill';
  }
}
function addLayerAsync(_callback, layer, visibility) {
  // do some asynchronous work
  // and when the asynchronous stuff is complete
  map.loadImage(
    'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
    (error, image) => {
      if (error) throw error;
      if (!map.hasImage(`custom-marker${layer.tasq_id}`)) {
        map.addImage(`custom-marker${layer.tasq_id}`, image);
      }
      // Add a GeoJSON source with 2 points
      if (map.getSource(layer.shapefileName) != null) {
        console.log('Source already exists, skipping...');
      } else {
        map.addSource(layer.shapefileName, {
          type: 'geojson',
          data: geoJsonByID[layer.id],
        });
        // Add a symbol layer
        map.addLayer({
          id: layer.shapefileName,
          type: 'symbol',
          source: layer.shapefileName,
          layout: {
            'icon-image': `custom-marker${layer.tasq_id}`,
            // get the title name from the source's "title" property
            'text-field': ['get', 'title'],
            'text-font': [
              'Open Sans Semibold',
              'Arial Unicode MS Bold',
            ],
            'text-offset': [0, 1.25],
            'text-anchor': 'top',
            visibility,
          },
          paint: {
            'text-color': '#ffffff',
          },
        });
      }
      if (map.getSource(layer.shapefileName) != null) {
        console.log('Source already exists, skipping...');
      } else {
        map.addSource(layer.shapefileName, {
          type: 'geojson',
          data: geoJsonByID[layer.id],
        });
        // Add a symbol layer
        map.addLayer({
          id: layer.shapefileName,
          type: 'symbol',
          source: layer.shapefileName,
          layout: {
            'icon-image': `custom-marker${layer.tasq_id}`,
            // get the title name from the source's "title" property
            'text-field': ['get', 'title'],
            'text-font': [
              'Open Sans Semibold',
              'Arial Unicode MS Bold',
            ],
            'text-offset': [0, 1.25],
            'text-anchor': 'top',
            visibility,
          },
          paint: {
            'text-color': '#ffffff',
          },
        });
      }
    },
  );
  _callback();
}
function setupMapviewLayersV2(layers) {
  for (let x = 0; x < layers.length; x++) {
    const layer = layers[x];
    if (layer.assetType == 'METADATA_TABLE_WELLS') {
      addSinglePointMarkersToMap(layer);
      continue;
    }
    let visibility = 'visible';
    if (!layer.enabled) {
      visibility = 'none';
    }
		document.getElementById(layer.id)!.innerHTML = '';
		for (let g = 0; g < geoJsonByID[layer.id].features.length; g++) {
		  addAssetToConsole(layer, null, geoJsonByID[layer.id].features[g], '');
		}
		map.addSource(layer.id, {
		  type: 'geojson',
		  data: geoJsonByID[layer.id],
		});
		// map.addLayer({
		// 	id: layer.shapefileName,
		// 	type: get_type(layer.assetType),
		// 	source: layer.shapefileName, // reference the data source
		// 	layout: {
		// 		visibility,
		// 	},
		// 	paint: {
		// 		'line-width': 1.5,
		// 		'line-color': ['get', 'color'],
		// 	},
		// });
		map.addLayer({
		  id: `polygon-${layer.id}`,
		  type: 'fill',
		  source: layer.id,
		  layout: {
		    visibility,
		  },
		  paint: {
		    'fill-color': '#888888',
		    'fill-opacity': 0.4,
		  },
		  filter: ['==', '$type', 'Polygon'],
		});
		map.addLayer({
		  id: `multipolygon-${layer.id}`,
		  type: 'fill',
		  source: layer.id,
		  layout: {
		    visibility,
		  },
		  paint: {
		    'fill-color': '#CCCCCC',
		    'fill-opacity': 0.6,
		  },
		  filter: ['match', ['geometry-type'], ['MultiPolygon'], true, false],
		});
		map.addLayer({
		  id: `point-${layer.id}`,
		  type: 'circle',
		  source: layer.id,
		  layout: {
		    visibility,
		  },
		  paint: {
		    'circle-radius': 6,
		    'circle-color': '#B42222',
		  },
		  filter: ['==', '$type', 'Point'],
		});
		map.addLayer({
		  id: `linestring-${layer.id}`,
		  type: 'line',
		  source: layer.id,
		  layout: {
		    visibility,
		  },
		  paint: {
		    'line-width': 1.5,
		    'line-color': '#0092bf',
		  },
		  filter: ['==', '$type', 'LineString'],
		});
  }
}
window.setActiveTasqForDialog = setActiveTasqForDialog
function setActiveTasqForDialog(data, type= '', name = ''){
  console.log(window.event)
  if(type === 'Producing'){
      // tasqsListModule.activeProducingTasq() '');
  tasqsListModule.setKanbanSelectedTasq(name|| '');
  }
  if(data && data.properties && data.properties.NewTasqId){
    console.log(data.properties.NewTasqId)
     tasqsListModule.setActiveTasq(data.properties.NewTasqId || '');
  tasqsListModule.setKanbanSelectedTasq(data.properties.NewTasqId|| '');
}
}
window.setActiveTasqForPopup = setActiveTasqForPopup
function setActiveTasqForPopup(){
  let data = (window.popupTasq)
 if(data && data.properties && data.properties.NewTasqId ){
    console.log(data.properties)
  tasqsListModule.setKanbanSelectedTasq(data.properties.NewTasqId);
     assetsModule.setActiveProducingTasq('')
     tasqsListModule.setActiveTasq(data.properties.NewTasqId);
}else {
  tasqsListModule.setKanbanSelectedTasq(data.properties.NodeID);
  assetsModule.setActiveProducingTasq( data.properties.NodeID)
   tasqsListModule.setActiveTasq('');
}
}
declare const window: any;
// declare let popupTasq: any;
window.setActiveTasqForDialog = setActiveTasqForDialog
function addAssetToConsole(layer, el, feature, randomWellName) {
  if (layer.assetType == 'METADATA_TABLE_WELLS') {
    var console_div = document.getElementById(layer.id);
    var node_id = feature.properties.NodeID;
    var prediction_type = feature.properties.PredictionType;
    var feature_div = document.createElement('div');
    feature_div.style.boxShadow = '0 10px 15px -3px rgba(0, 0, 0, 0.2), 0 4px 6px -2px rgba(0, 0, 0, 0.2)';
    feature_div.style.width = '280px';
    feature_div.style.height = '105px';
    feature_div.style.float = 'left';
    feature_div.style.marginRight = '20px';
    feature_div.style.marginTop = '5px';
    feature_div.style.marginBottom = '25px';
    feature_div.style.borderRadius = '5px';
    feature_div.style.padding = '12px';
    feature_div.style.background = 'rgba(255,255,255,0.18)';
    feature_div.classList.add('growDiv');
    console.log(prediction_type.toLowerCase())
    const state_p = document.createElement('p');
    if (prediction_type.toLowerCase() == 'state change' || prediction_type.toLowerCase() == 'prolonged anomaly') {
      prediction_type = 'anomaly';
    }else if (prediction_type.toLowerCase() == 'off-target rt') {
      prediction_type = 'Real-Time';
    }
    state_p.innerHTML = prediction_type.toUpperCase();
    state_p.style.boxShadow = '0 10px 15px -3px rgba(0, 0, 0, 0.15), 0 4px 6px -2px rgba(0, 0, 0, 0.15)';
    state_p.style.paddingLeft = '15px';
    state_p.style.paddingRight = '15px';
    state_p.style.paddingTop = '5px';
    state_p.style.paddingBottom = '5px';
    state_p.style.color = 'white';
    state_p.style.fontSize = '11px';
    state_p.style.fontWeight = '500';
    state_p.style.float = 'right';
    state_p.style.borderRadius = '5px';
    var well_p = document.createElement('p');
    well_p.innerHTML = randomWellName.toUpperCase();
    well_p.style.color = 'white';
    well_p.style.fontSize = '15px';
    well_p.style.fontWeight = '500';
    well_p.style.float = 'left';
    well_p.style.width = 'calc(100% - 110px)';
    well_p.style.height = '20px';
    const completion_section_div = document.createElement('div');
    completion_section_div.style.clear = 'both';
    completion_section_div.style.float = 'left';
    completion_section_div.style.marginTop = '35px';
    completion_section_div.style.width = '100%';
    const first_completion_div = document.createElement('div');
    first_completion_div.style.width = '8px';
    first_completion_div.style.height = '8px';
    first_completion_div.style.borderRadius = '8px';
    first_completion_div.style.background = 'rgba(255,255,255,0.3)';
    first_completion_div.style.float = 'left';
    first_completion_div.style.marginRight = '4px';
    first_completion_div.style.marginTop = '4px';
    const second_completion_div = document.createElement('div');
    second_completion_div.style.width = '8px';
    second_completion_div.style.height = '8px';
    second_completion_div.style.borderRadius = '8px';
    second_completion_div.style.background = 'rgba(255,255,255,0.3)';
    second_completion_div.style.float = 'left';
    second_completion_div.style.marginRight = '4px';
    second_completion_div.style.marginTop = '4px';
    const third_completion_div = document.createElement('div');
    third_completion_div.style.width = '8px';
    third_completion_div.style.height = '8px';
    third_completion_div.style.borderRadius = '8px';
    third_completion_div.style.background = 'rgba(255,255,255,0.3)';
    third_completion_div.style.float = 'left';
    third_completion_div.style.marginRight = '4px';
    third_completion_div.style.marginTop = '4px';
    const fourth_completion_div = document.createElement('div');
    fourth_completion_div.style.width = '8px';
    fourth_completion_div.style.height = '8px';
    fourth_completion_div.style.borderRadius = '8px';
    fourth_completion_div.style.background = 'rgba(255,255,255,0.3)';
    fourth_completion_div.style.float = 'left';
    fourth_completion_div.style.marginRight = '4px';
    fourth_completion_div.style.marginTop = '4px';
    const actioned_label = document.createElement('p');
    // actioned_label.innerHTML = "Not actioned"
    actioned_label.style.color = 'rgba(255,255,255,0.6)';
    actioned_label.style.fontSize = '12px';
    actioned_label.style.fontWeight = '500';
    actioned_label.style.float = 'left';
    actioned_label.style.marginLeft = '4px';
    // completion_section_div.appendChild(first_completion_div)
    // completion_section_div.appendChild(second_completion_div)
    // completion_section_div.appendChild(third_completion_div)
    // completion_section_div.appendChild(fourth_completion_div)
    completion_section_div.appendChild(actioned_label);
    if (prediction_type.toLowerCase() == 'off-target') {
      state_p.classList.add('bg-tasqOrange');
    } else if (prediction_type.toLowerCase() == 'off-target rt') {
      state_p.classList.add('bg-tasqRed');
    } else if (prediction_type.toLowerCase() == 'setpoint') {
      state_p.classList.add('bg-tasqBlue');
    } else if (prediction_type.toLowerCase() == 'no comms') {
      state_p.classList.add('bg-tasqGrayDark');
    } else if (prediction_type.toLowerCase() == 'anomaly' || prediction_type.toLowerCase() == 'state change' || prediction_type.toLowerCase() == 'prolonged anomaly') {
      state_p.classList.add('bg-tasqYellowDark');
    } else if (prediction_type.toLowerCase() == 'label') {
      state_p.classList.add('bg-tasqBlueLight');
    } else if (prediction_type.toLowerCase() == 'failure') {
      state_p.classList.add('bg-tasqRed');
    } else if (prediction_type.toLowerCase() == 'prediction') {
      state_p.classList.add('bg-tasqGreen');
    } else if (prediction_type.toLowerCase() == 'workflow' || prediction_type.toLowerCase() == 'pm') {
      state_p.classList.add('bg-tasqViolet');
    } else {
      state_p.classList.add('bg-tasqGrayDark');
    }
    feature_div.appendChild(state_p);
    feature_div.appendChild(well_p);
    if (feature.properties.Assignee != null) {
      const assignee_label = document.createElement('p');
      assignee_label.innerHTML = feature.properties.Assignee;
      assignee_label.style.color = 'rgba(255,255,255,1)';
      assignee_label.style.fontSize = '12px';
      assignee_label.style.fontWeight = '500';
      assignee_label.style.float = 'right';
      completion_section_div.appendChild(assignee_label);
    }
    feature_div.onclick = function () { // asign a function
      // code
      map.flyTo({
        center: feature.geometry.coordinates,
        zoom: 14,
        offset: [0, -160],
      });
      if(window.innerWidth > 800){
        setActiveTasqForDialog(feature,prediction_type, randomWellName)
      }
    };
    if (prediction_type.toLowerCase() == 'off-target') {
      state_p.classList.add('bg-tasqOrange');
      const off_target_section_div = document.createElement('div');
      off_target_section_div.style.clear = 'both';
      off_target_section_div.style.marginTop = '0px';
      off_target_section_div.style.width = '100%';
      completion_section_div.style.marginTop = '20px';
      feature_div.appendChild(off_target_section_div);
    }
	if (prediction_type.toLowerCase() == 'off-target rt') {
      state_p.classList.add('bg-tasqRed');
      const off_target_section_div = document.createElement('div');
      off_target_section_div.style.clear = 'both';
      off_target_section_div.style.marginTop = '0px';
      off_target_section_div.style.width = '100%';
      completion_section_div.style.marginTop = '20px';
      feature_div.appendChild(off_target_section_div);
    }
    feature_div.appendChild(completion_section_div);
        console_div!.appendChild(feature_div);
  } else if (layer.assetType == 'WELLS') {
    var console_div = document.getElementById(layer.id);
    var node_id = feature.properties.NodeID;
    var prediction_type = feature.properties.PredictionType;
    var feature_div = document.createElement('div');
    feature_div.style.width = '100%';
    feature_div.style.float = 'left';
    feature_div.style.marginTop = '5px';
    feature_div.style.marginBottom = '25px';
    feature_div.style.borderBottom = 'solid 1px rgba(255,255,255,0.2)';
    var well_p = document.createElement('p');
    well_p.innerHTML = feature.properties[layer.selectedAssetNameOption];
    // if (feature.properties.NAME != null) {
    // 	well_p.innerHTML = feature.properties.NAME
    // } else if (feature.properties.name != null) {
    // 	well_p.innerHTML = feature.properties.name
    // } else {
    // 	well_p.innerHTML = feature.properties[Object.keys(feature.properties)[0]];
    // }
    // well_p.innerHTML = feature.properties.Pipename
    well_p.style.color = 'white';
    well_p.style.fontSize = '16px';
    well_p.style.fontWeight = '500';
    well_p.style.float = 'left';
    well_p.style.width = 'calc(100% - 110px)';
    well_p.style.height = '20px';
    well_p.style.marginBottom = '20px';
    feature_div.appendChild(well_p);
        console_div!.appendChild(feature_div);
  } else if (layer.assetType == 'PIPELINES') {
    var console_div = document.getElementById(layer.id);
    var node_id = feature.properties.NodeID;
    var prediction_type = feature.properties.PredictionType;
    var feature_div = document.createElement('div');
    feature_div.style.width = '100%';
    feature_div.style.float = 'left';
    feature_div.style.marginTop = '15px';
    feature_div.style.marginBottom = '15px';
    feature_div.style.borderBottom = 'solid 1px rgba(255,255,255,0.2)';
    var well_p = document.createElement('p');
    well_p.innerHTML = feature.properties[layer.selectedAssetNameOption];
    // if (feature.properties.NAME != null) {
    // 	well_p.innerHTML = feature.properties.NAME
    // } else if (feature.properties.Pipename != null) {
    // 	well_p.innerHTML = feature.properties.Pipename
    // } else if (feature.properties.name != null) {
    // 	well_p.innerHTML = feature.properties.name
    // } else {
    // 	well_p.innerHTML = feature.properties[Object.keys(feature.properties)[0]];
    // }
    well_p.style.color = 'white';
    well_p.style.fontSize = '15px';
    well_p.style.fontWeight = '500';
    well_p.style.float = 'left';
    well_p.style.width = 'calc(100% - 110px)';
    well_p.style.marginBottom = '20px';
    var down_arrow = document.createElement('span');
    down_arrow.innerHTML = 'expand_more';
    down_arrow.style.color = 'rgba(255,255,255,0.8)';
    down_arrow.style.fontSize = '23px';
    down_arrow.style.fontWeight = '500';
    down_arrow.style.float = 'right';
    down_arrow.classList.add('material-icons');
    feature_div.appendChild(well_p);
        // feature_div.appendChild(down_arrow)
        console_div!.appendChild(feature_div);
  } else if (layer.assetType == 'LEASE_BOUNDARIES') {
    var console_div = document.getElementById(layer.id);
    var node_id = feature.properties.NodeID;
    var prediction_type = feature.properties.PredictionType;
    var feature_div = document.createElement('div');
    feature_div.style.width = '100%';
    feature_div.style.float = 'left';
    feature_div.style.marginTop = '15px';
    feature_div.style.marginBottom = '15px';
    feature_div.style.borderBottom = 'solid 1px rgba(255,255,255,0.2)';
    var well_p = document.createElement('p');
    well_p.innerHTML = feature.properties[layer.selectedAssetNameOption];
    // if (feature.properties.NAME != null) {
    // 	well_p.innerHTML = feature.properties.NAME
    // } else if (feature.properties.name != null) {
    // 	well_p.innerHTML = feature.properties.name
    // } else {
    // 	well_p.innerHTML = feature.properties[Object.keys(feature.properties)[0]];
    // }
    well_p.style.color = 'white';
    well_p.style.fontSize = '15px';
    well_p.style.fontWeight = '500';
    well_p.style.float = 'left';
    well_p.style.width = 'calc(100% - 110px)';
    well_p.style.marginBottom = '20px';
    var down_arrow = document.createElement('span');
    down_arrow.innerHTML = 'expand_more';
    down_arrow.style.color = 'rgba(255,255,255,0.8)';
    down_arrow.style.fontSize = '23px';
    down_arrow.style.fontWeight = '500';
    down_arrow.style.float = 'right';
    down_arrow.classList.add('material-icons');
    feature_div.appendChild(well_p);
        // feature_div.appendChild(down_arrow)
        console_div!.appendChild(feature_div);
  } else if (layer.assetType == 'VALVES') {
    var console_div = document.getElementById(layer.id);
    var node_id = feature.properties.NodeID;
    var prediction_type = feature.properties.PredictionType;
    var feature_div = document.createElement('div');
    feature_div.style.width = '100%';
    feature_div.style.float = 'left';
    feature_div.style.marginTop = '15px';
    feature_div.style.marginBottom = '15px';
    feature_div.style.borderBottom = 'solid 1px rgba(255,255,255,0.2)';
    var well_p = document.createElement('p');
    well_p.innerHTML = feature.properties[layer.selectedAssetNameOption];
    well_p.style.color = 'white';
    well_p.style.fontSize = '15px';
    well_p.style.fontWeight = '500';
    well_p.style.float = 'left';
    well_p.style.width = 'calc(100% - 110px)';
    well_p.style.marginBottom = '20px';
    var down_arrow = document.createElement('span');
    down_arrow.innerHTML = 'expand_more';
    down_arrow.style.color = 'rgba(255,255,255,0.8)';
    down_arrow.style.fontSize = '23px';
    down_arrow.style.fontWeight = '500';
    down_arrow.style.float = 'right';
    down_arrow.classList.add('material-icons');
    feature_div.appendChild(well_p);
        // feature_div.appendChild(down_arrow)
        console_div!.appendChild(feature_div);
  } else if (layer.assetType == 'OTHER') {
    var console_div = document.getElementById(layer.id);
    var node_id = feature.properties.NodeID;
    var prediction_type = feature.properties.PredictionType;
    var feature_div = document.createElement('div');
    feature_div.style.width = '100%';
    feature_div.style.float = 'left';
    feature_div.style.marginTop = '15px';
    feature_div.style.marginBottom = '15px';
    feature_div.style.borderBottom = 'solid 1px rgba(255,255,255,0.2)';
    var well_p = document.createElement('p');
    well_p.innerHTML = feature.properties[layer.selectedAssetNameOption];
    well_p.style.color = 'white';
    well_p.style.fontSize = '15px';
    well_p.style.fontWeight = '500';
    well_p.style.float = 'left';
    well_p.style.width = 'calc(100% - 110px)';
    well_p.style.marginBottom = '20px';
    var down_arrow = document.createElement('span');
    down_arrow.innerHTML = 'expand_more';
    down_arrow.style.color = 'rgba(255,255,255,0.8)';
    down_arrow.style.fontSize = '23px';
    down_arrow.style.fontWeight = '500';
    down_arrow.style.float = 'right';
    down_arrow.classList.add('material-icons');
    feature_div.appendChild(well_p);
        // feature_div.appendChild(down_arrow)
        console_div!.appendChild(feature_div);
  }
}
function updatePointMapMarkers(mapLayer, layout_id, remove_markers) {
  if (wellMarkers[mapLayer.id] != null) {
    removePointMarkers(mapLayer.id);
  } else if (map.getLayer(`point-${layout_id}`)) {
    const visibility = map.getLayoutProperty(
      `point-${layout_id}`,
      'visibility',
    );
    if (visibility === 'visible') {
      updateMapviewLayout(layout_id, true);
    } else if (!remove_markers) {
      if (mapLayer.assetType == 'METADATA_TABLE_WELLS') {
        addSinglePointMarkersToMap(mapLayer);
      } else {
        updateMapviewLayout(layout_id, remove_markers);
      }
    }
  } else if (!remove_markers) {
    if (mapLayer.assetType == 'METADATA_TABLE_WELLS') {
      addSinglePointMarkersToMap(mapLayer);
    } else {
      updateMapviewLayout(layout_id, remove_markers);
    }
  }
}
function removePointMarkers(layer_id) {
  if (wellMarkers[layer_id] != null) {
    for (let x = 0; x < wellMarkers[layer_id].length; x++) {
      wellMarkers[layer_id][x].remove();
    }
    delete wellMarkers[layer_id];
  }
}
function addSinglePointMarkersToMap(layer) {
    document.getElementById(layer.id) !.innerHTML = '';
    if (layer.assetType == 'METADATA_TABLE_WELLS') {
      // add markers to map
      geoJsonByID[layer.id].features.forEach((marker) => {
        // create a HTML element for each feature
        const el = document.createElement('div');
        // var predictionType = getWellStatus(marker.properties.NodeID, tasqs)
        if (marker.properties.PredictionType.toLowerCase() == 'off-target') {
          el.className = 'off-target-marker';
        } else if (marker.properties.PredictionType.toLowerCase() == 'off-target rt') {
          el.className = 'failure-marker';
        } else if (marker.properties.PredictionType.toLowerCase() == 'state change' || marker.properties.PredictionType.toLowerCase() == 'anomaly' || marker.properties.PredictionType.toLowerCase() == 'prolonged anomaly') {
          el.className = 'state-change-marker';
        } else if (marker.properties.PredictionType.toLowerCase() == 'failure') {
          el.className = 'failure-marker';
        } else if (marker.properties.PredictionType.toLowerCase() == 'prediction') {
          el.className = 'prediction-marker';
        } else if (marker.properties.PredictionType.toLowerCase() == 'no comms') {
          el.className = 'no-comms-marker';
        } else if (marker.properties.PredictionType.toLowerCase() == 'pm') {
          el.className = 'workflow-marker';
        } else if (marker.properties.PredictionType.toLowerCase() == 'label') {
          el.className = 'manual-job-marker';
        } else if (marker.properties.PredictionType.toLowerCase() == 'workflow') {
          el.className = 'workflow-marker';
        } else if (marker.properties.PredictionType.toLowerCase() == 'setpoint') {
          el.className = 'setpoint-marker';
        } else {
          el.className = 'no-comms-marker';
        }
        addAssetToConsole(layer, el, marker, marker.properties.NodeID);
        // make a marker for each feature and add it to the map
        window.TaskID = marker;
        const map_marker = new mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates)
          .setPopup(
            new mapboxgl.Popup({
              offset: 25,
            }) // add popups
             .setHTML(
                `<h2 id=${marker.properties.NodeID} onclick="setActiveTasqForPopup()" style="overflow: hidden; text-overflow: ellipsis; line-height: 1rem; min-height: white-space: nowrap; margin-left: 15px; width: calc(100% - 30px); text-align: center; cursor: pointer; margin-auto; font-weight: 500; font-size: 15px;">${
                  marker.properties.NodeID
                }</h2><p>${
                  marker.properties.PredictionType === 'Off-Target RT' ? 'Real-Time': marker.properties.PredictionType
                }</p><p>${
                  marker.properties.PredictionType === 'Off-Target RT' ?  marker.properties.outcome: ''
                }</p>`,
              ),
          )
          .addTo(map);
          map_marker.getElement().addEventListener('click', () => {
              window.popupTasq = marker
        });
          
        if (wellMarkers[layer.id] == null) {
          wellMarkers[layer.id] = [map_marker];
        } else {
          wellMarkers[layer.id].push(map_marker);
        }
      });
    } else {
      geoJsonByID[layer.id].features.forEach((marker) => {
        // create a HTML element for each feature
        const el = document.createElement('div');
        if (layer.assetType == 'VALVES') {
          el.className = 'valve-blue-marker';
        } else if (layer.assetType == 'WELLS') {
          el.className = 'no-comms-marker';
        }
        addAssetToConsole(layer, el, marker, marker.properties.NodeID);
        // make a marker for each feature and add it to the map
        const map_marker = new mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates)
          .addTo(map);
        if (wellMarkers[layer.id] == null) {
          wellMarkers[layer.id] = [map_marker];
        } else {
          wellMarkers[layer.id].push(map_marker);
        }
      });
    }
}
var geoJsonByID = {};
function fetch_geo_json(layers, metadataTableWellsRecord, tasqs) {
  AWS.config.update({
    region: SHAPEFILE_BUCKET_REGION,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: IDENTITY_POOL_ID,
    }),
  });
  const s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: {
      Bucket: SHAPEFILE_BUCKET_NAME,
    },
  });
  const getS3Object = (key_params, layer_id) => new Promise((resolve, reject) => {
    s3.getObject(key_params, (err, data) => {
      // Handle any error and exit
      if (err) {
        console.log(err);
        reject(err);
      } else {
        const result_object = {
          layer_id,
          geo_json: data.Body!.toString('utf-8'),
        };
        resolve(result_object);
      }
    });
  });
  const promises: any[] = [];
  for (let d = 0; d < layers.length; d++) {
    const layer = layers[d];
    if (layer.assetType == 'METADATA_TABLE_WELLS') {
      continue;
    }
    const getParams = {
      Bucket: layer.bucket, // your bucket name,
      Key: layer.key, // path to the object you're looking for
    };
    promises.push(getS3Object(getParams, layer.id));
  }
  return Promise.all(promises)
    .then((pres) => {
      for (let t = 0; t < layers.length; t++) {
        for (let p = 0; p < pres.length; p++) {
          if (layers[t].id == pres[p].layer_id) {
            geoJsonByID[layers[t].id] = JSON.parse(pres[p].geo_json);
          }
        }
      }
      geoJsonByID[metadataTableWellsRecord.id] = setupGeoJsonPredictionTypes(metadataTableWellsRecord.geoJson, tasqs);
      load_map(layers, tasqs);
    })
    .catch((res) => {
      console.log(`Error Getting Templates: ${res}`);
    });
}
function setupGeoJsonPredictionTypes(geo_json, tasqs) {
  for (let b = 0; b < geo_json.features.length; b++) {
    let wellIsProducing = true;
    for (let z = 0; z < tasqs.length; z++) {
        // geo_json.features[b].properties.NewTasqId = tasqs[z].wellName;
      if (tasqs[z].wellName.toLowerCase() == geo_json.features[b].properties.NodeID.toLowerCase()) {
        wellIsProducing = false;
        geo_json.features[b].properties.Assignee = tasqs[z].username.toLowerCase();
        geo_json.features[b].properties.PredictionType = tasqs[z].predictionType;
        geo_json.features[b].properties.NewTasqId = tasqs[z].id;
        geo_json.features[b].properties.outcome = getOverridenRealTimeLabel(tasqs[z].outcome);
      }
    }
    if (wellIsProducing) {
      geo_json.features[b].properties.PredictionType = 'Producing';
      //  geo_json.features[b].properties.NewTasqId = tasqs[z].id;
    }
  }
  return geo_json;
}

function getOverridenRealTimeLabel(label) {
    console.log(label)
	  if (label && label.toLowerCase() == 'rtd') {
		  return 'Deferment';
	  }
	  if (label && label.toLowerCase() == 'hiline') {
		  return 'Line Pressure';
	  }
	  return label;
  }

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
function getZoomCoordinated(layers) {
  const lat: any[] = [];
  const long: any[] = [];
  for (let x = 0; x < layers.length; x++) {
    const layer = layers[x];
    if (layer.assetType == 'METADATA_TABLE_WELLS') {
      for (let y = 0; y < layer.geoJson.features.length; y++) {
        long.push(layer.geoJson.features[y].geometry.coordinates[0]);
        lat.push(layer.geoJson.features[y].geometry.coordinates[1]);
      }
      // addSinglePointMarkersToMap(layer)
      break;
    }
  }
  const lat_sum = lat.reduce((a, b) => a + b, 0);
  const long_sum = long.reduce((a, b) => a + b, 0);
  return [long_sum / long.length, lat_sum / lat.length];
}
function load_map(layers, tasqs) {
  sleep(1000).then(() => {
    mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;
    map = new mapboxgl.Map({
      container: 'mapview_container',
      style: 'mapbox://styles/tasqinc/cknqk70x402e617nvqb5svmy7',
      center: getZoomCoordinated(layers),
      zoom: 8,
    });
    map.on('load', () => {
      setupMapviewLayersV2(layers);
      // setupMapviewLayers(layers, tasqs);
    });
  });
}
